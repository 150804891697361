.sidebar {
    position: fixed;
    top: 0;
    left: -100vw;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    overflow-y: scroll;
    transition: all .5s ease-in-out;
    z-index: 11;
    &.active{
        left: 0;
    }
    a{
        text-decoration: none !important;
    }
    &__logo {
        display: grid;
        place-items: center;
        height: 120px;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: 'Mochiy Pop P one', sans-serif;
    }

    &__menu {
        position: relative;

        &__item {
            display: flex;
            align-items: center;
            place-content: flex-start;
            padding: 1rem 3rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: #555555;
            width: fit-content;
            transition: color 0.3s ease-in-out;

            &.active {
                color: #fff;
            }

            &__icon {
                margin-right: 1rem;

                i {
                    font-size: 1.75rem;
                }
            }
        }

        &__indicator {
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-radius: 10px;
            background-color: #308efe;
            z-index: -1;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
        }
    }
}
.hamburger{
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 111;
    width: 50px;
    height: 50px;
    display: inline-block;
    cursor: pointer;
    .bar1,.bar2,.bar3{
        width: 35px;
        height: 5px;
        background-color: #333;
        margin: 6px 0;
        transition: 0.4s;
    }
    &.active{
        .bar1 {
            transform: translate(0, 11px) rotate(-45deg);
          }
          
          .bar2 {opacity: 0;}
          
          .bar3 {
            transform: translate(0, -11px) rotate(45deg);
          }
    }
}